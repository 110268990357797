@font-face {
    font-family: 'Circular Std';
    src: './fonts/CircularStd-Book.ttf' format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Circular Std';
    src: './fonts/CircularStd-Book.ttf' format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Circular Std';
    src: './fonts/CircularStd-Book.ttf' format('truetype');
    font-weight: 700;
    font-style: normal;
  }