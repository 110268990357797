
@import url('https://res.cloudinary.com/dsty70mlq/raw/upload/v1724838630/Homesy/h10klq6doqqhk501mw7o.ttf');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Circular Std';
}

main {
  padding: 1rem;
  min-height: 100vh;
}

body {
  background-image: url('https://res.cloudinary.com/dsty70mlq/image/upload/v1725287626/Homesy/background_k8u0jt.png');
  background-repeat: no-repeat;
}

article {
  font-size: 1.2rem;
  width: 90%;
  margin: 1rem;
}

button {
  margin: 1rem 1rem 0.5rem;
  font: inherit;
  font-size: 2rem;
  padding: 0.5em 1.5em;
  cursor: pointer;
  border-radius: 0.5rem;
}

.start-btn{
  padding: 25px 64px !important;
}
.next-first-btn{
  padding: 16px 56px !important;
}
.input-placeholder input::placeholder{
  color: #3B4547;
  font-size: 22px;
  font-weight: 450;
  line-height: 18;
  font-family: Circular Std, sans-serif;

}
.input-placeholder input[type=checkbox]{
  width: 28px;
  height: 28px;
  border-radius: 6px;
  outline: 1px solid #3B4547;
  margin-right: 15px;
  margin-left: 2px;

}
.last-icon{
  background-color: rgba(214, 108, 66, 0.1);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 25px;
}



.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.focus\:border-transparent:focus{
  border-color: #e3e3e3 !important;
}

input:-webkit-autofill {
  background-color: #F8F7F5 !important;
  transition: background-color 5000s ease-in-out 0s;
}

@tailwind base;
@tailwind components;
@tailwind utilities;